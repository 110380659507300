export default function useSubProductGroupProducts() {
  const formatLabel = (p) => {
    const name = p?.product?.name ?? '[-]';
    const unit = p.unit ? ` ${p.unit}` : '';
    const min = p.minAmount ?? 0;
    const max = p.maxAmount === null ? '∞' : p.maxAmount;
    let amount = '';

    if (min === max) {
      amount = `${min}${unit}`;
    } else if (min && max) {
      amount = `${min} - ${max}${unit}`;
    } else if (!min && max) {
      amount = `0 - ${max}${unit}`;
    } else if (min && !max) {
      amount = `>= ${min}${unit}`;
    }

    return `${name} (${amount})`;
  };

  return { formatLabel };
}
