<template>
  <div>
    <div class="sm:grid sm:grid-cols-12">
      <div class="col-span-6 md:col-span-4 md:border-r md:pr-4">
        <h1 class="mb-4" v-text="t('productGroup.label', 2)" />
        <tree-view
          v-for="group in parentProductGroups"
          :key="group['@id']"
          :parent-value="group"
          label="name"
          path="/product_groups"
          :active-id="selectedProductGroup"
          :actions="['edit']"
          @click:create="openModal({
            ...$event,
            name: '',
            products: [],
            inHouseVatRate: null,
            toGoVatRate: null,
            noteTemplates: [],
            addAsSubProduct: false,
          }, 'editProductGroup')"
          @click:edit="openModal(normalizer($event), 'editProductGroup')"
          @click:delete="openModal($event, 'deleteProductGroup')"
          @click:entry="loadProducts($event)"
        />
      </div>
      <div ref="productsView" class="hidden sm:block col-span-6 md:col-span-8 px-4">
        <product-index-page
          v-if="selectedProductGroup"
          ref="products"
          :new-product-product-group="selectedProductGroup"
          :load-immediately="typeof router?.currentRoute?.value?.query?.productGroup !== 'undefined'"
        />
      </div>
    </div>

    <delete-modal
      type="deleteProductGroup"
      entity-translation-key="productGroup"
      label="name"
      @update="loadData"
    >
      <template #additional>
        <p>
          <strong v-text="t('productGroup.deleteWarning')" />
        </p>
      </template>
    </delete-modal>
    <edit-product-group-modal @update="loadData" />
  </div>
</template>

<script>
import { nextTick, onMounted, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import notify from '@kyvg/vue3-notification';
import { useRouter } from 'vue-router';
import axios from '@/services/axios';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import EditProductGroupModal from '@/components/entities/product_group/EditProductGroupModal.vue';
import TreeView from '@/components/utils/TreeView.vue';
import ProductIndexPage from '@/components/pages/products/products/index.vue';

export default {
  name: 'ProductGroupsIndexPage',
  components: {
    ProductIndexPage,
    TreeView,
    EditProductGroupModal,
    DeleteModal,
    EntityTable,
  },
  setup() {
    const router = useRouter();
    const selectedProductGroup = ref(router?.currentRoute?.value?.query?.productGroup);
    const store = useStore();
    const { t } = useI18n();
    const entity = ref({});
    const parentProductGroups = ref([]);
    const products = ref(null);
    const productsView = ref(null);

    const openModal = (e, modal) => {
      store.dispatch('modals/openModal', { modal, entity: e });
    };

    const normalizer = (e) => {
      console.log(e);

      return {
        ...e,
        products: e.products?.map((p) => p['@id']),
        inHouseVatRate: e.inHouseVatRate?.['@id'],
        toGoVatRate: e.toGoVatRate?.['@id'],
      };
    };

    const loadData = async () => {
      try {
        parentProductGroups.value = [];
        const { data } = await axios.get('/product_groups', {
          params: {
            'exists[parent]': false,
            hideDeleted: true,
          },
        });
        if (data['hydra:member']) {
          parentProductGroups.value = data['hydra:member'];
          if (typeof selectedProductGroup.value !== 'string') {
            selectedProductGroup.value = data['hydra:member'][0]['@id'];
            nextTick(() => {
              products.value.updateFilters({ productGroup: selectedProductGroup.value });
            });
          }
        }
      } catch (err) {
        console.error(err, err.message, err.response);
        notify({
          title: 'Fehler beim Laden der Elemente',
          text: err?.response?.data?.['hydra:description'] ?? null,
          type: 'error',
        });
      }
    };

    const loadProducts = (group) => {
      selectedProductGroup.value = group['@id'];
      nextTick(() => {
        products.value.updateFilters({ productGroup: group['@id'] });
      });
      try {
        if (window.getComputedStyle(productsView.value).display === 'none') {
          router.push({ name: 'products', query: { productGroup: group['@id'] } });
        }
      } catch (err) {
        console.error('Could not check if we should navigate');
      }
    };

    onMounted(async () => {
      await loadData();
    });

    return {
      t,
      entity,
      parentProductGroups,
      openModal,
      normalizer,
      loadData,
      selectedProductGroup,
      products,
      productsView,
      loadProducts,
      router,
    };
  },
};
</script>
<style scoped lang="scss">
.tree-wrapper {
  @apply border-r;
}
</style>
