<template>
  <div>
    <div class="grid grid-cols-2 gap-4">
      <fancy-input v-model="model.template" type="text" :label="$t('productNoteTemplate.label')" />
    </div>
    <a href="#" class="text-sm underline" @click.prevent="$emit('click:remove', model)">
      {{ $t('general.action.removeSth', [$t('productNoteTemplate.label')]) }}
    </a>
    <hr>
  </div>
</template>
<script>
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import { useModel } from '@/hooks/model';

export default {
  name: 'ProductNoteTemplateForm',
  components: { FancyInput },
  props: {
    modelValue: { type: Object, default: () => ({}) },
  },
  emits: ['click:remove'],
  setup(props, { emit }) {
    const model = useModel(props, emit);

    return {
      model,
    };
  },
};
</script>
