<template>
  <div>
    <div class="tabs">
      <div
        v-for="tab in tabs"
        :key="tab.key"
        class="tab"
        :class="{'active': (tab.key === selectedTabState.selectedTabKey)}"
        @click="selectedTabState.selectedTabKey = tab.key"
      >
        {{ tab.title }}
      </div>
    </div>
    <slot />
  </div>
</template>

<script>
import {
  ref, onMounted, provide, reactive,
} from 'vue';

export default {
  setup(props, { slots }) {
    const tabs = ref([]);
    const selectedTabState = reactive({ selectedTabKey: null });
    provide('selectedTabState', selectedTabState);
    onMounted(() => {
      tabs.value = slots.default().map((i) => {
        if (i.props && i.props['tab-key']) {
          return {
            key: i.props['tab-key'],
            title: i.props.title,
          };
        }
        return undefined;
      });
      selectedTabState.selectedTabKey = tabs.value[0] && tabs.value[0].key;
    });
    return { tabs, selectedTabState };
  },
};
</script>

<style lang="scss" scoped>
</style>
