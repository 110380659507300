<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editProduct"
    :normalizer="saveNormalizer"
    :options="{
      properties: {
        name: '',
        productNumber: '',
        productGroup: null,
        subProductGroups: [],
        pricingMode: PRODUCT_PRICING_MODES.PRICING_MODE_FIX,
        productType: PRODUCT_TYPES.PRODUCT_TYPE_NORMAL,
        baseWeights: [],
        price: null,
        inHouseVatRate: null,
        toGoVatRate: null,
      },
      postUri: '/products',
      label: 'Produkt',
    }"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" :error="errors.name" required />
    <fancy-input v-model="properties.productNumber" :label="$t('product.productNumber.label')" :error="errors.productNumber" required />
    <multiselect
      v-model="properties.productGroup"
      :options="async (q) => loadProductGroupOptions(q, entity.productGroup)"
      :loading="loadingProductGroupOptions"
      :filter-results="false"
      :error="errors.productGroup"
      label="name"
      :form-label="$t('productGroup.label')"
      searchable
      required
    />
    <multiselect
      v-model="properties.subProductGroups"
      :options="async (q) => loadSubProductGroupOptions(q, entity.subProductGroups)"
      :loading="loadingSubProductGroupOptions"
      :filter-results="false"
      :error="errors.subProductGroups"
      mode="tags"
      label="name"
      :form-label="$t('subProductGroup.label', 2)"
      searchable
    />
    <simple-dropdown
      v-model="properties.pricingMode"
      :options="pricingModeOptions"
      :error="errors.pricingMode"
      :label="$t('product.pricingMode.label')"
    />
    <simple-dropdown
      v-model="properties.productType"
      :options="productTypes"
      :error="errors.productType"
      :label="$t('product.productType.label')"
    />
    <multiselect
      v-model="properties.baseWeights"
      :options="async (q) => loadBaseWeightOptions(q, entity.baseWeights)"
      :loading="loadingBaseWeightOptions"
      :filter-results="false"
      :error="errors.baseWeights"
      mode="tags"
      label="name"
      :form-label="$t('baseWeight.label', 2)"
      searchable
    >
      <template #option="{ option }">
        {{ option.name }} ({{ option.weight }}g)
      </template>
      <template #tag="{ option, handleTagRemove }">
        <span v-if="option" class="multiselect-tag bg-primary">
          {{ option.name }}
          ({{ option.weight }}g)
          <span class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
            <span class="multiselect-tag-remove-icon" />
          </span>
        </span>
      </template>
    </multiselect>
    <vat-rate-form v-model="properties.inHouseVatRate" :error="errors.inHouseVatRate" :label="$t('vatRate.inHouse.label')">
      <template #inheritedVatRate>
        <template v-if="entity.applicableInHouseVatRate != null">
          {{ $t('vatRate.inHouse.label') }}
          ({{ $t('vatRate.inherited', [entity.applicableInHouseVatRate.name, entity.applicableInHouseVatRate.value * 100]) }})
        </template>
      </template>
    </vat-rate-form>
    <vat-rate-form v-model="properties.toGoVatRate" :error="errors.toGoVatRate" :label="$t('vatRate.toGo.label')">
      <template #inheritedVatRate>
        <template v-if="entity.applicableInHouseVatRate != null">
          {{ $t('vatRate.toGo.label') }}
          ({{ $t('vatRate.inherited', [entity.applicableInHouseVatRate.name, entity.applicableInHouseVatRate.value * 100]) }})
        </template>
      </template>
    </vat-rate-form>
    <price-form v-if="properties.pricingMode !== 30" v-model="properties.price" :label="$t('price.label')" :error="errors.price" />
  </modal-form>
</template>
<script>
import { useI18n } from 'vue-i18n';
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import VatRateForm from '@/components/shared/forms/VatRateForm.vue';
import PriceForm from '@/components/shared/forms/PriceForm.vue';
import { PRODUCT_PRICING_MODES, PRODUCT_TYPES } from '@/constants';

export default {
  name: 'EditProductModal',
  components: {
    PriceForm,
    VatRateForm,
    SimpleDropdown,
    FancyInput,
    ModalForm,
    Multiselect,
  },
  props: {
    entity: { type: Object, default: () => ({}) },
  },
  emits: ['update'],
  setup() {
    const { t } = useI18n();
    const {
      loading: loadingProductGroupOptions,
      loadOptions: loadProductGroupOptions,
    } = useLoadOptions('/product_groups', 'name');

    const {
      loading: loadingSubProductGroupOptions,
      loadOptions: loadSubProductGroupOptions,
    } = useLoadOptions('/sub_product_groups', 'name');

    const {
      loading: loadingBaseWeightOptions,
      loadOptions: loadBaseWeightOptions,
    } = useLoadOptions('/base_weights', 'name');

    const pricingModeOptions = [];
    Object.entries(PRODUCT_PRICING_MODES).forEach((entry) => {
      const [key, value] = entry;
      pricingModeOptions.push({ key, label: t(`product.pricingMode.${key}`), value });
    });

    const productTypes = [];
    Object.entries(PRODUCT_TYPES).forEach((entry) => {
      const [key, value] = entry;
      productTypes.push({ key, label: t(`product.productType.${key}`), value });
    });

    const saveNormalizer = (saveProps) => ({
      ...saveProps,
      price: saveProps.price ? {
        id: saveProps.price?.['@id'],
        amount: saveProps.price?.amount ?? 0,
        currency: saveProps.price?.currency ?? 'CHF',
      } : undefined,
    });

    return {
      saveNormalizer,
      loadingProductGroupOptions,
      loadProductGroupOptions,
      loadingSubProductGroupOptions,
      loadSubProductGroupOptions,
      loadingBaseWeightOptions,
      loadBaseWeightOptions,
      pricingModeOptions,
      productTypes,
      PRODUCT_TYPES,
      PRODUCT_PRICING_MODES,
    };
  },
};
</script>
