<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('subProductGroup.label', 2)" />
      <button
        class="small"
        @click="openModal({
          name: '',
          title: '',
          minChoices: 0,
          maxChoices: null,
          showInPopup: true,
          products: [],
          subProductGroupProducts: [],
        }, 'editSubProductGroup' )"
      >
        +
      </button>
    </div>

    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      :filters="filters"
      @update:filters="updateFilters"
      @update:sort="loadEntities()"
      @update:page="currentPage = $event; loadEntities()"
      @click:delete="openModal($event, 'deleteSubProductGroup')"
    />

    <edit-sub-product-group-modal @update="loadEntities" />
    <delete-modal type="deleteSubProductGroup" label="name" entity-translation-key="subProductGroup" @update="loadEntities" />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditSubProductGroupModal from '@/components/entities/sub_product_group/EditSubProductGroupModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  name: 'SubProductGroupIndex',
  components: { DeleteModal, EditSubProductGroupModal, EntityTable },
  setup() {
    const store = useStore();
    const { subProductGroup: fields } = useEntityFields();
    const {
      loadEntities,
      loadingEntities,
      sortDirection,
      sortBy,
      currentPage,
      totalItems,
      entities,
      filters,
      updateFilters,
    } = useLoadEntities(
      '/sub_product_groups',
      { fields, sort: 'name' },
    );

    const openModal = (entity, modal) => {
      store.dispatch('modals/openModal', { entity, modal });
    };

    return {
      fields,
      loadEntities,
      loadingEntities,
      sortDirection,
      sortBy,
      currentPage,
      totalItems,
      entities,
      filters,
      updateFilters,
      openModal,
    };
  },
};
</script>
