<template>
  <slot v-if="selectedTabState.selectedTabKey === tabKey" />
</template>

<script>
import { inject } from 'vue';

export default {
  name: 'Tab',
  props: {
    title: {
      type: String,
      required: true,
    },
    tabKey: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const selectedTabState = inject('selectedTabState');
    return { ...props, selectedTabState };
  },
};
</script>

<style scoped>

</style>
