<template>
  <div>
    <spinner v-if="loading" color="black" />
    <div class="element">
      <div
        class="py-2 entry flex justify-between"
        :class="{[`child-count-${elements ? elements.length : 0}`]: true, active: parentValue['@id'] === activeId }"
        @click="$emit('click:entry', parentValue)"
      >
        <div>
          <span v-text="parentValue[label]" />
          <button @click.stop="$emit('click:create', { parent: parentValue['@id'] })">
            +
          </button>
        </div>
        <div>
          <a
            v-if="actions.includes('edit')"
            href="#"
            @click.stop.prevent="$emit('click:edit', parentValue)"
          >
            <edit-icon class="inline-block w-4 h-4 mr-2" />
          </a>
          <span :class="{disabled: !actions.includes('delete')}">
            <a
              href="#"
              @click.stop.prevent="$emit('click:delete', parentValue)"
            >
              <close-icon class="inline-block w-4 h-4" />
            </a>
          </span>
        </div>
      </div>
      <div class="sub-elements">
        <div
          v-for="element in elements"
          :key="element['@id']"
          class="element-list"
        >
          <tree-view
            :path="path"
            :parent-prop="parentProp"
            :parent-value="element"
            :level="level + 1"
            :label="label"
            :actions="['edit', 'delete']"
            :active-id="activeId"
            @click:create="$emit('click:create', $event)"
            @click:edit="$emit('click:edit', $event)"
            @click:delete="$emit('click:delete', $event)"
            @click:entry="$emit('click:entry', $event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from 'vue';
import axios from '@/services/axios';
import Spinner from '@/components/utils/spinner.vue';
import EditIcon from '@/assets/images/edit.svg';
import CloseIcon from '@/assets/images/close.svg';
import getIdFromIri from '@/utils/getIdFromIri';

export default {
  name: 'TreeView',
  components: { Spinner, EditIcon, CloseIcon },
  props: {
    parentProp: { type: String, default: 'parent' },
    parentValue: { type: Object, required: true },
    level: { type: Number, default: 0 },
    path: { type: String, default: null },
    label: { type: String, default: 'label' },
    actions: { type: Array, default: () => ['edit', 'delete'] },
    activeId: { type: String, default: null },
  },
  emits: ['click:create', 'click:edit', 'click:delete', 'click:entry'],
  setup(props) {
    const loading = ref(true);
    const elements = ref([]);

    axios.get(props.path, {
      params: {
        [props.parentProp]: getIdFromIri(props.parentValue['@id']),
        hideDeleted: true,
        pagination: false,
      },
    }).then(({ data }) => {
      if (data['hydra:member']) {
        elements.value = data['hydra:member'];
      }
      loading.value = false;
    });

    return {
      loading,
      elements,
    };
  },
};
</script>

<style lang="scss" scoped>
.element {
  @apply relative;
  .sub-elements {
    @apply relative pl-4;
    &:last-child {
    }
  }
  .entry {
    @apply relative px-6;
    cursor: pointer;
    &.active {
      background-color: #DDD;
    }
    &:hover {
      background-color: #CCC;
    }
    &:not(.child-count-0) {
      &:before {
        @apply absolute border-l border-gray-400;
        top: 50%;
        width: 1px;
        left: 1rem;
        height: 50%;
        content: '';
      }
    }
    &:after {
      @apply absolute border-b border-gray-400;
      top: 1.2rem;
      width: 1.4rem;
      left: 0;
      height: 1px;
      content: '';
    }
    button {
      height: auto;
      padding: 0 0.4rem;
      margin-left: 0.4rem;
      aspect-ratio: 1;
    }
  }
  .element-list {
    &:not(:last-child) {
      @apply relative border-l border-gray-400;
    }
    &:last-child {
      >div>.element:last-child {
        >.entry {
          &:after {
            @apply absolute border-l border-b border-gray-400 bg-transparent;
            top: 0;
            width: 1.4rem;
            left: 0;
            height: 1.3rem;
            content: '';
          }
        }
      }
    }
  }
}
</style>
