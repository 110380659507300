<template>
  <div>
    <div class="center-between mb-4">
      <h1 class="m-0" v-text="$t('product.label', 2)" />
      <button
        class="small"
        @click="openModal({
          name: '',
          productNumber: '',
          productGroup: newProductProductGroup,
          subProductGroups: [],
          pricingMode: 10,
          baseWeights: [],
          price: { currency: 'CHF', amount: null },
          inHouseVatRate: null,
          toGoVatRate: null,
        }, 'editProduct')"
      >
        +
      </button>
    </div>

    <button
      v-if="Object.keys(filters).some((f) => !!filters[f])"
      class="white small mb-4 visible sm:invisible"
      @click="resetFilters(); loadEntities();"
      v-text="$t('product.showAll')"
    />
    <entity-table
      v-model:sortBy="sortBy"
      v-model:sortDirection="sortDirection"
      :fields="fields"
      :entities="entities"
      :loading="loadingEntities"
      :current-page="currentPage"
      :total-items="totalItems"
      :filters="filters"
      @update:filters="updateFilters"
      @update:page="currentPage = $event; loadEntities()"
      @update:sort="loadEntities()"
      @click:delete="openModal($event, 'deleteProduct')"
    />

    <edit-product-modal @update="loadEntities" />
    <delete-modal
      type="deleteProduct"
      entity-translation-key="product"
      label="name"
      @update="loadEntities"
    />
  </div>
</template>

<script>
import { useStore } from 'vuex';
import useEntityFields from '@/hooks/api/fields';
import useLoadEntities from '@/hooks/api/loadEntities';
import EntityTable from '@/components/shared/EntityTable/EntityTable.vue';
import EditProductModal from '@/components/entities/product/EditProductModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';

export default {
  name: 'ProductIndexPage',
  components: { DeleteModal, EditProductModal, EntityTable },
  props: {
    newProductProductGroup: {
      type: String,
      default: null,
    },
    loadImmediately: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const { dispatch } = useStore();
    const { product: fields } = useEntityFields();
    const {
      entities,
      filters,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      updateFilters,
      resetFilters,
    } = useLoadEntities('/products', { fields, sort: 'productNumber', loadImmediately: props.loadImmediately });
    const openModal = (entity, modal) => {
      dispatch('modals/openModal', { entity, modal });
    };

    return {
      fields,
      entities,
      filters,
      loadingEntities,
      currentPage,
      totalItems,
      sortBy,
      sortDirection,
      loadEntities,
      updateFilters,
      resetFilters,
      openModal,
    };
  },
};
</script>
