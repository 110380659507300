<template>
  <div>
    <spinner v-if="loading" color="#000" />
    <template v-else>
      <entity-card
        :entity="entity"
        :title="entity.name"
        :fields="fields"
        class="-mt-4"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              class="small white mr-2"
              @click="openModal(entity, 'deleteSubProductGroup')"
              v-text="t('general.action.delete')"
            />
            <button class="small" @click="openModal(normalizedEntity, 'editSubProductGroup')" v-text="t('general.action.edit')" />
          </div>
        </template>

        <template #products>
          <span v-if="entity.products.length === 0" v-text="t('general.none')" />
          <admin-entity-link
            v-for="p in entity.products"
            v-else
            :key="p['@id']"
            class="badge white mr-2"
            :to="p"
          >
            {{ p.name }}
          </admin-entity-link>
        </template>

        <template #subProductGroupProducts>
          <span v-if="entity.subProductGroupProducts.length === 0" v-text="t('general.none')" />
          <admin-entity-link
            v-for="p in entity.subProductGroupProducts"
            v-else
            :key="p['@id']"
            class="badge mr-2 mb-2"
            :to="p.product"
          >
            {{ formatLabel(p) }}
          </admin-entity-link>
        </template>
      </entity-card>

      <edit-sub-product-group-modal @update="loadData" />
      <delete-modal
        type="deleteSubProductGroup"
        entity-translation-key="subProductGroup"
        label="name"
        @update="$router.push('/sub_product_groups')"
      />
    </template>
  </div>
</template>

<script>
import { useStore } from 'vuex';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import useSubProductGroupProducts from '@/hooks/api/subProductGroupProducts';
import axios from '@/services/axios';
import EntityCard from '@/components/shared/EntityCard.vue';
import Spinner from '@/components/utils/spinner.vue';
import EditSubProductGroupModal from '@/components/entities/sub_product_group/EditSubProductGroupModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import AdminEntityLink from '@/components/utils/AdminEntityLink.vue';

export default {
  name: 'SubProductGroupDetail',
  components: {
    DeleteModal,
    EditSubProductGroupModal,
    Spinner,
    EntityCard,
    AdminEntityLink,
  },
  setup() {
    const { t } = useI18n();
    const route = useRoute();
    const { formatLabel } = useSubProductGroupProducts();

    const entity = ref(null);
    const loading = ref(true);

    const normalizedEntity = computed(() => ({
      ...entity.value,
      products: entity.value.products.map((p) => p['@id']),
      subProductGroupProducts: entity.value.subProductGroupProducts.map((p) => ({ ...p, product: p?.product?.['@id'] })),
    }));

    const fields = ref([
      { key: 'name', label: t('general.field.name') },
      { key: 'title', label: t('general.field.title') },
      { key: 'minChoices', label: t('subProductGroup.minChoices.label') },
      { key: 'maxChoices', label: t('subProductGroup.maxChoices.label'), formatter: (val) => (val == null ? '∞' : val) },
      { key: 'products', label: t('subProductGroup.products.label') },
      {
        key: 'subProductGroupProducts',
        label: t('subProductGroup.subProductGroupProduct.label', entity.value?.subProductGroupProducts?.length ?? 2),
      },
    ]);

    const loadData = async () => {
      loading.value = true;

      try {
        const { data } = await axios.get(`/sub_product_groups/${route.params.id}`);
        entity.value = data;
      } catch (err) {
        console.error(err, err.message, err.response);
      }

      loading.value = false;
    };

    const store = useStore();
    const openModal = (e, modal) => {
      store.dispatch('modals/openModal', { entity: e, modal });
    };

    loadData();

    return {
      t,
      formatLabel,
      loading,
      entity,
      normalizedEntity,
      fields,
      loadData,
      openModal,
    };
  },
};
</script>
