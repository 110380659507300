<template>
  <div class="p-4 border">
    <div class="center-between">
      <h3 v-text="t('subProductGroup.subProductGroupProduct.label')" />
      <close-icon class="block w-4 cursor-pointer transition-colors hover:text-primary" @click="$emit('remove', modelValue['@id'])" />
    </div>

    <fancy-input
      v-model="model.minAmount"
      :label="t('subProductGroup.subProductGroupProduct.minAmount')"
      :min="0"
      type="number"
      required
      class="mt-4"
    />
    <fancy-input
      v-model="model.maxAmount"
      :label="t('subProductGroup.subProductGroupProduct.maxAmount')"
      :min="0"
      type="number"
      class="mt-4"
    />
    <fancy-input
      v-model="model.unit"
      :label="t('subProductGroup.subProductGroupProduct.unit')"
      required
      class="mt-4"
    />
    <multiselect
      v-model="model.product"
      :loading="loadingProductOptions"
      :filter-results="false"
      :options="async (q) => loadProductOptions(q, model.product)"
      :form-label="t('product.label')"
      searchable
      label="name"
    >
      <template #option="{ option }">
        <span v-if="option">
          {{ option.name }}
          ({{ option.productNumber }})
        </span>
      </template>
      <template #singlelabel="{ option }">
        <span v-if="option">
          {{ option.name }}
          ({{ option.productNumber }})
        </span>
      </template>
    </multiselect>
  </div>
</template>

<script>
import { useI18n } from 'vue-i18n';
import { useModel } from '@/hooks/model';
import useLoadOptions from '@/hooks/api/loadOptions';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import CloseIcon from '@/assets/images/close.svg';

export default {
  name: 'SubProductGroupProductForm',
  components: {
    FancyInput,
    Multiselect,
    CloseIcon,
  },
  props: {
    modelValue: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ['remove', 'update:modelValue'],
  setup(props, { emit }) {
    const { t } = useI18n();
    const model = useModel(props, emit);

    const {
      loading: loadingProductOptions,
      loadOptions: loadProductOptions,
    } = useLoadOptions('/products', ['productNumber', 'name']);

    return {
      t,
      model,
      loadingProductOptions,
      loadProductOptions,
    };
  },
};
</script>
