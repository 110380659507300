<template>
  <modal-form
    v-slot="{ properties, errors }"
    type="editSubProductGroup"
    :normalizer="saveNormalizer"
    :options="{
      properties: {
        name: '',
        title: '',
        minChoices: 0,
        maxChoices: null,
        showInPopup: true,
        products: [],
        subProductGroupProducts: [],
      },
      postUri: '/sub_product_groups',
      label: $t('subProductGroup.label'),
    }"
    @update="$emit('update')"
  >
    <fancy-input v-model="properties.name" :label="$t('general.field.name')" />
    <fancy-input v-model="properties.title" :label="$t('general.field.title')" />
    <fancy-checkbox v-model="properties.showInPopup" :label="$t('subProductGroup.showInPopup.label')" />
    <fancy-input
      v-model="properties.minChoices"
      :label="$t('subProductGroup.minChoices.label')"
      type="number"
      :min="0"
      :step="1"
    />
    <fancy-input
      v-model="properties.maxChoices"
      :label="$t('subProductGroup.maxChoices.label')"
      type="number"
      :min="0"
      :step="1"
    />
    <multiselect
      v-model="properties.products"
      mode="tags"
      :loading="loadingProductOptions"
      :filter-results="false"
      :options="async (q) => loadProductOptions(q, properties.products)"
      :error="errors.products"
      searchable
      label="name"
      :form-label="$t('subProductGroup.products.label', 2)"
    >
      <template #option="{ option }">
        <span v-if="option">
          {{ option.name }}
          ({{ option.productNumber }})
        </span>
      </template>
      <template #tag="{ option, handleTagRemove }">
        <span v-if="option" class="multiselect-tag bg-primary">
          {{ option.name }}
          ({{ option.productNumber }})
          <span class="multiselect-tag-remove" @click="handleTagRemove(option, $event)">
            <span class="multiselect-tag-remove-icon" />
          </span>
        </span>
      </template>
    </multiselect>

    <sub-product-group-product-form
      v-for="(p, i) in properties.subProductGroupProducts"
      :key="p?.['@id'] ?? 'sub-' + i"
      :model-value="p"
      @update:modelValue="($e) => { p = $e; }"
      @remove="removeSubProductGroupProduct(properties, $event)"
    />
    <button class="white small mt-4" @click="addSubProductGroupProduct(properties)" v-text="$t('subProductGroup.addSubProductGroupProduct')" />
  </modal-form>
</template>

<script>
import { useStore } from 'vuex';
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import SubProductGroupProductForm from '@/components/entities/sub_product_group/SubProductGroupProductForm.vue';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';

export default {
  name: 'EditSubProductGroupModal',
  components: {
    FancyCheckbox,
    SubProductGroupProductForm,
    FancyInput,
    ModalForm,
    Multiselect,
  },
  props: {
    entity: { type: Object, default: () => ({}) },
  },
  emits: ['update'],
  setup(props, { emit }) {
    const store = useStore();

    const {
      loading: loadingProductOptions,
      loadOptions: loadProductOptions,
    } = useLoadOptions('/products', ['name', 'productNumber']);

    const saveNormalizer = (e) => ({
      ...e,
      maxChoices: !e.maxChoices ? null : e.maxChoices,
      subProductGroupProducts: e.subProductGroupProducts.map((p) => ({
        ...p,
        '@id': undefined,
        id: p?.['@id'],
        product: typeof p.product === 'string' ? p.product : p.product['@id'],
      })),
    });

    const removeSubProductGroupProduct = (properties, iri) => {
      const idx = properties.subProductGroupProducts.findIndex((p) => p['@id'] === iri);
      if (idx > -1) {
        properties.subProductGroupProducts.splice(idx, 1);
      }
    };

    const addSubProductGroupProduct = (properties) => {
      properties.subProductGroupProducts.push({
        minAmount: 0,
        maxAmount: null,
        unit: '',
        product: null,
      });
    };

    return {
      saveNormalizer,
      loadProductOptions,
      loadingProductOptions,
      removeSubProductGroupProduct,
      addSubProductGroupProduct,
    };
  },
};
</script>
