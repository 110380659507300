<template>
  <div>
    <modal-form
      v-slot="{ properties, errors }"
      type="editProductGroup"
      :normalizer="normalizer"
      :options="{
        properties: {
          name: '',
          inHouseVatRate: null,
          toGoVatRate: null,
          parent: '',
          noteTemplates: [],
          addAsSubProduct: false,
        },
        label: 'Produktgruppe',
        postUri: '/product_groups',
      }"
      @update="$emit('update')"
    >
      <tabs>
        <tab :title="$t('general.configuration')" tab-key="1">
          <div class="pt-4">
            <fancy-input v-model="properties.name" :error="errors.name" :label="$t('general.field.name')" />
            <fancy-checkbox v-model="properties.addAsSubProduct" :label="$t('productGroup.addAsSubProduct.label')" />
            <vat-rate-form v-model="properties.inHouseVatRate" :error="errors.inHouseVatRate" :label="$t('vatRate.inHouse.label')" class="mt-4" />
            <vat-rate-form v-model="properties.toGoVatRate" :error="errors.toGoVatRate" :label="$t('vatRate.toGo.label')" class="mt-4" />
          </div>
        </tab>
        <tab :title="$t('productNoteTemplate.label', 2)" tab-key="2">
          <div class="pt-4">
            <div class="text-right">
              <button
                class="small white"
                type="button"
                @click="addProductNoteTemplate(properties)"
              >
                +
              </button>
            </div>
            <product-note-template-form
              v-for="(noteTemplate, idx) in properties.noteTemplates"
              :key="noteTemplate['@id']"
              :model-value="noteTemplate"
              @update:model-value="(e) => noteTemplate = e"
              @click:remove="removeProductNoteTemplate(properties, idx)"
            />
          </div>
        </tab>
      </tabs>
    </modal-form>
  </div>
</template>
<script>
import useLoadOptions from '@/hooks/api/loadOptions';
import ModalForm from '@/components/shared/ModalForm.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import VatRateForm from '@/components/shared/forms/VatRateForm.vue';
import Tabs from '@/components/utils/Tabs.vue';
import Tab from '@/components/utils/Tab.vue';
import ProductNoteTemplateForm from '@/components/entities/product_group/ProductNoteTemplateForm.vue';
import FancyCheckbox from '@/components/utils/forms/FancyCheckbox.vue';

export default {
  name: 'EditProductGroupModal',
  components: {
    FancyCheckbox,
    ProductNoteTemplateForm,
    Tab,
    Tabs,
    VatRateForm,
    Multiselect,
    FancyInput,
    ModalForm,
  },
  props: {
    entity: { type: Object, default: () => ({}) },
  },
  emits: ['update'],
  setup() {
    const {
      loading: loadingOptions,
      loadOptions,
    } = useLoadOptions('/products', ['search[name]', 'search[productNumber]']);

    const addProductNoteTemplate = (props) => {
      props.noteTemplates.push({
        template: '',
      });
    };

    const removeProductNoteTemplate = (props, idx) => {
      props.noteTemplates.splice(idx, 1);
    };

    const normalizer = (entity) => ({
      ...entity,
      noteTemplates: entity.noteTemplates.map((t) => ({
        ...t,
        '@id': undefined,
        id: t?.['@id'],
      })),
    });

    return {
      loadingOptions,
      loadOptions,
      addProductNoteTemplate,
      removeProductNoteTemplate,
      normalizer,
    };
  },
};
</script>
