<template>
  <div>
    <simple-dropdown
      v-model="vatRate"
      :options="vatRateOptions"
      :error="error"
      :label="label"
    >
      <template #label>
        <slot name="inheritedVatRate" />
      </template>
    </simple-dropdown>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n';
import Multiselect from '@/components/utils/forms/Multiselect.vue';
import FancyInput from '@/components/utils/forms/FancyInput.vue';
import SimpleDropdown from '@/components/utils/forms/SimpleDropdown.vue';
import { useModel } from '@/hooks/model';

export default {
  name: 'VatRateForm',
  components: { SimpleDropdown, FancyInput, Multiselect },
  props: {
    modelValue: { type: String, default: null },
    label: { type: String, default: 'MwSt.-Satz' },
    error: { type: String, default: null },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const store = useStore();
    const vatRate = useModel(props, emit);
    const { t } = useI18n();

    if (!store.state.vatRates.vatRates.length) {
      store.dispatch('vatRates/LOAD_VAT_RATES');
    }

    const vatRateOptions = computed(() => [
      { key: 'none', value: null, label: t('vatRate.inherit') },
      ...store.state.vatRates.vatRates,
    ]);

    return {
      vatRateOptions,
      vatRate,
    };
  },
};
</script>
