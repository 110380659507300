<template>
  <router-tabs :links="links">
    <router-view class="pt-4" />
  </router-tabs>
</template>

<script>
import { ref } from 'vue';
import { useI18n } from 'vue-i18n';
import RouterTabs from '@/components/utils/RouterTabs.vue';

export default {
  components: { RouterTabs },
  setup() {
    const { t } = useI18n();

    return {
      links: ref([
        { id: 'products', route: { name: 'products' }, text: t('product.label', 2) },
        { id: 'product-groups', route: { name: 'product-groups' }, text: t('productGroup.label', 2) },
        { id: 'sub-product-groups', route: { name: 'sub-product-groups' }, text: t('subProductGroup.label', 2) },
      ]),
    };
  },
};
</script>
