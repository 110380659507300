<template>
  <div>
    <spinner v-if="loading" color="#000" />
    <template v-else>
      <entity-card
        :entity="entity"
        :title="entity.name"
        :fields="fields"
        class="-mt-4"
        big-heading
      >
        <template #action>
          <div class="entity-detail-page-actions">
            <button
              v-t="'general.action.delete'"
              class="small white mr-2"
              @click="openModal(entity, 'deleteProduct')"
            />
            <button v-t="'general.action.edit'" class="small" @click="openModal(normalizedProduct, 'editProduct')" />
          </div>
        </template>

        <template #productGroup>
          <admin-entity-link class="badge" :to="entity.productGroup">
            {{ entity.productGroup.name }}
          </admin-entity-link>
        </template>

        <template #subProductGroups>
          <span v-if="!entity.subProductGroups.length" v-t="'general.none'" />
          <admin-entity-link
            v-for="group in entity.subProductGroups"
            v-else
            :key="group['@id']"
            class="badge mr-2 mb-2"
            :to="group"
          >
            {{ group.name }}
          </admin-entity-link>
        </template>

        <template #pricingMode>
          {{ $t('product.pricingMode.' + Object.keys(PRODUCT_PRICING_MODES).find(key => PRODUCT_PRICING_MODES[key] === entity.pricingMode)) }}
        </template>

        <template #productType>
          {{ $t('product.productType.' + Object.keys(PRODUCT_TYPES).find(key => PRODUCT_TYPES[key] === entity.productType)) }}
        </template>

        <template #baseWeights>
          <span v-if="!baseWeights.length" v-t="'general.none'" />
          <span
            v-for="weight in baseWeights"
            v-else
            :key="weight['@id']"
            class="badge mr-2 mb-2"
            v-text="`${weight.name} (${weight.weight}g)`"
          />
        </template>
      </entity-card>

      <edit-product-modal @update="loadData" />
      <delete-modal
        type="deleteProduct"
        entity-translation-key="product"
        label="name"
        @update="$router.push('/products')"
      />
    </template>
  </div>
</template>

<script>
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';
import { useStore } from 'vuex';
import axios from '@/services/axios';
import getIdFromIri from '@/utils/getIdFromIri';
import formatPrice from '@/utils/formatPrice';
import Spinner from '@/components/utils/spinner.vue';
import EntityCard from '@/components/shared/EntityCard.vue';
import EditProductModal from '@/components/entities/product/EditProductModal.vue';
import DeleteModal from '@/components/shared/DeleteModal.vue';
import { PRODUCT_PRICING_MODES, PRODUCT_TYPES } from '@/constants';

export default {
  name: 'ProductDetailPage',
  components: {
    DeleteModal,
    EditProductModal,
    EntityCard,
    Spinner,
  },
  setup() {
    const route = useRoute();
    const { t } = useI18n();
    const { dispatch } = useStore();
    const entity = ref(null);
    const normalizedProduct = computed(() => ({
      ...entity.value,
      productGroup: entity.value.productGroup?.['@id'] ?? null,
      baseWeights: entity.value.baseWeights?.map((w) => w['@id']) ?? [],
      subProductGroups: entity.value.subProductGroups?.map((g) => g['@id']) ?? [],
      inHouseVatRate: entity.value.inHouseVatRate?.['@id'] ?? null,
      toGoVatRate: entity.value.toGoVatRate?.['@id'] ?? null,
      price: entity.value.price ?? { currency: 'CHF', amount: null },
    }));
    const loading = ref(true);
    const baseWeights = ref([]);
    const openModal = (e, modal) => {
      dispatch('modals/openModal', { modal, entity: e });
    };

    const fields = ref([
      { key: 'productNumber', label: t('product.productNumber.label') },
      { key: 'productGroup', label: t('productGroup.label') },
      { key: 'subProductGroups', label: t('subProductGroup.label', 2) },
      { key: 'pricingMode', label: t('product.pricingMode.label') },
      { key: 'productType', label: t('product.productType.label') },
      { key: 'baseWeights', label: t('baseWeight.label', 2) },
      {
        key: 'price',
        label: t('price.label'),
        formatter: formatPrice,
      },
      {
        key: 'inHouseVatRate',
        label: t('vatRate.inHouse.label'),
        formatter: (val) => {
          if (val != null) {
            return `${val.name} (${val.value * 100}%)`;
          }
          if (val == null && entity.value.applicableInHouseVatRate != null) {
            return `${entity.value.applicableInHouseVatRate.name} (${entity.value.applicableInHouseVatRate.value * 100}%) (vererbt)`;
          }
          return '';
        },
      },
      {
        key: 'toGoVatRate',
        label: t('vatRate.toGo.label'),
        formatter: (val) => {
          if (val != null) {
            return `${val.name} (${val.value * 100}%)`;
          }
          if (val == null && entity.value.applicableToGoVatRate != null) {
            return `${entity.value.applicableToGoVatRate.name} (${entity.value.applicableToGoVatRate.value * 100}%) (vererbt)`;
          }
          return '';
        },
      },
    ]);

    const loadData = async () => {
      loading.value = true;
      try {
        const { data } = await axios(`/products/${route.params.id}`);
        entity.value = data;

        if (entity.value.baseWeights.length) {
          const { data: weights } = await axios('/base_weights', {
            params: {
              id: entity.value.baseWeights.map((w) => getIdFromIri(w['@id'])),
            },
          });
          if (weights['hydra:member']) {
            baseWeights.value = weights['hydra:member'];
          }
        }
      } catch (err) {
        console.error(err, err.message, err.response);
      }
      loading.value = false;
    };

    loadData();

    return {
      t,
      entity,
      normalizedProduct,
      baseWeights,
      fields,
      loading,
      loadData,
      formatPrice,
      openModal,
      PRODUCT_TYPES,
      PRODUCT_PRICING_MODES,
    };
  },
};
</script>
